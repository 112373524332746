<template>
  <div class="about">
      about
  </div>
</template>

<script>
import axios from 'axios'
export default {
  setup() {
  },
  data() {
      return {

      };
  },
  methods: {

  }
}
</script>
